/* eslint-env browser */
/**
 *
 * Mini Dropdown menu
 * 
<div class="dropdown flex-end mb-40">
    <div class="custom-select" aria-label="Select your menu profile">
        <button class="btn item_select" type="submit" id="dropdown" aria-haspopup="true" aria-expanded="false">
            Welcome decio.bernardo
        </button>
        <ul class="invisible menu-content">
            <li>
                <a href="topics-interface.html" class="uppercase">
                    Manage topics
                </a>
            </li>
            <li>
                <a href="settings-interface.html" class="uppercase">
                    Settings
                </a>
            </li>
            <li>
                <a href="#" class="uppercase">
                    Logout
                </a>
            </li>
        </ul>
    </div>
</div>
 *
 * Original by T.M.K
 * Adaptation by Edwin Joassart
 */

class Dropdown {
  constructor(dropdownButtonElement) {
    this.buttonElement = dropdownButtonElement;
    this.menuElement =
      dropdownButtonElement.closest('div').querySelector('.menu-content') || // for simple dropdown
      dropdownButtonElement
        .closest('.menuWrapper')
        .querySelector('.menu-content'); // for complex one like `other website`s

    this.buttonElement.addEventListener('click', this.toggleMenu.bind(this));

    // reference to document click event listener (exists only if dropdown is open, so we don't polute document)
    this.clickOutsideEventListener = null;
  }

  /**
   * Click event handler
   * Open dropdown
   */
  openMenu() {
    this.buttonElement.setAttribute('aria-expanded', 'true');
    this.menuElement.classList.remove('no-display');
    this.menuElement.classList.remove('invisible');
    this.menuElement.style.display = 'block';
    this.menuElement.setAttribute('aria-expanded', 'true');

    // add document click eventListener
    this.clickOutsideEventListener = this.closeMenuFromOutsideClick.bind(this);
    document.addEventListener('click', this.clickOutsideEventListener);
  }

  /**
   * Click event handler
   * Close dropdown
   */
  closeMenu() {
    this.buttonElement.setAttribute('aria-expanded', 'false');
    this.menuElement.classList.add('no-display');
    this.menuElement.style.display = 'none';
    this.menuElement.setAttribute('aria-expanded', 'false');

    // clear document click eventListener
    document.removeEventListener('click', this.clickOutsideEventListener);
  }

  /**
   * Click event handler
   * Close menu if click happens outside the dropdown
   */
  closeMenuFromOutsideClick(event_) {
    if (!event_.target.closest('.dropdown')) this.closeMenu.bind(this)();
  }

  /**
   * Click event handler
   * Tooggle menu open/close
   */
  toggleMenu(event_) {
    event_.preventDefault();
    if (this.menuElement.classList.contains('no-display'))
      this.openMenu.bind(this)();
    else this.closeMenu.bind(this)();
  }

  static init() {
    const searchParameters = document.location.search;
    for (const link of document.querySelectorAll('.dropdown a')) {
      link.setAttribute(
        'href',
        `${link.getAttribute('href')}${searchParameters}`
      );
    }

    console.info('Dropdown : 1.0');
    return new Dropdown(document.querySelector('#dropdown'));
  }
}

document.addEventListener('DOMContentLoaded', () => {
  Dropdown.init();
});
